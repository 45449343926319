import React from "react"
import Container from "@ecom/ui/components/Container"
import Button from "@material-ui/core/Button"

import * as styles from "./elektronikaVRassrochku.module.scss"
import { handleClickBanner } from "../../../helpers/WatcherDL/utils/handleClickBanner"

import { TypeBannerProps } from "../helpers"

const buttonText = "Заказать карту"

export default function Banner({ orderNum }: TypeBannerProps) {
  return (
    <section className={styles.section} data-exclude={orderNum}>
      <Container className={styles.root}>
        <h1 className={styles.head}>Рассрочка - 0% на электронику и товары для геймеров</h1>
        <Button className={styles.btn} onClick={() => handleClickBanner("click_cta")}>
          {buttonText}
        </Button>
      </Container>
    </section>
  )
}
